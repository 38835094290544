import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './My Components/Navbar';
import './App.css';
import './My Components/Navbar.css';
import './My Components/Header.css';
import Header from './My Components/Header';
import Gallery from './My Components/Gallery';
import './My Components/Gallery.css';
import About from './My Components/About';
import './My Components/About.css';
import Home from './My Components/Home';
import './My Components/Home.css';
import Contact from './My Components/Contact';
import './My Components/Contact.css';
import Footer from './My Components/Footer';
import './My Components/Footer.css';
import GoToTop from './My Components/GoToTop';
import './My Components/GoToTop.css';
import ScrollToTop from './My Components/ScrollToTop';
import Accomodation from './My Components/Accomodation';
import './My Components/Accomodation.css';
import SightSeeing from './My Components/SightSeeing';
import './My Components/SightSeeing.css';

function App() {
  return (

    <div className="App">
      

      <BrowserRouter>
      <ScrollToTop />
      <Header/>
       <Navbar/>
      <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/accomodation" element={<Accomodation/>} />
      {/* <Route path="/sightseeing" element={<SightSeeing/>} /> */}
      <Route path="/gallery" element={<Gallery/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="*" element={<Navigate to="/home" />} />
       </Routes>
       <Footer/>
       <GoToTop />
       </BrowserRouter>
       
    </div>
  );
}

export default App;
