import React from 'react'

export default function Accomodation() {
    return (
        <div className="about-page mt-5 mb-5 pt-3">
            <div className="container">
                <div className="row title align-items-center">
                    <div className="col text-start">
                        <h2 className="display-6 fw-normal">Accomodation</h2>
                    </div>
                    <div className="col subtitle text-end">
                        OUR TARIFF & POLICIES
                    </div>
                </div>

                <div className="row text-center mt-5 py-5">
                    <div className="col table-responsive">
                        <table class="table table-hover table-bordered table align-middle">
                            <thead >
                                <tr className='tablehead'>
                                    <th scope="col">Room Category</th>
                                    <th scope="col">EP</th>
                                    <th scope="col">CP</th>
                                    <th scope="col">MAP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">Deluxe Room Single</th>
                                    <td>Rs. 2500/-</td>
                                    <td>Rs. 3000/-</td>
                                    <td>Rs. 4000/-</td>
                                </tr>
                                <tr>
                                    <th scope="row">Deluxe Room Double</th>
                                    <td>Rs. 2500/-</td>
                                    <td>Rs. 3000/-</td>
                                    <td>Rs. 4000/-</td>
                                </tr>
                                <tr>
                                    <th scope="row">Extra Bed</th>
                                    <td>Rs. 800/-</td>
                                    <td>Rs. 1000/-</td>
                                    <td>Rs. 1200/-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
