import React from 'react'

export default function About() {

    

    return (
        <><div className="about-page mt-5 mb-5 pt-3">
            <div className="container">
                <div className="row title align-items-center">
                    <div className="col text-start">
                        <h2 className="display-6 fw-normal">About Us</h2>
                    </div>
                    <div className="col subtitle text-end">
                        OUR HISTORY
                    </div>
                </div>
                <div className="row mt-5 gy-5 gx-5 align-items-center">
                    <div className="col-lg-2 col-md-4 history-image">
                        <img src="Images/About/history2.webp"  alt="" />
                    </div>
                    <div className="col-lg-10 col-md-8 history-content">
                        <p>
                            <li><i class="bi bi-star-fill history-icons"></i> Late Rao Bahadur, Colonel, Thakur Jeoraj Singh of Harasar is the Father of Sh. Arjun Singh, the owner of Hotel Desert Winds. He hailed from village Sarothia, 150 Kms from Bikaner and was born in 1905. He completed his studies from Walter Nobles High School, Bikaner and joined Dungar Lancers (Bikaner State Army) as a commissioned Officer in 1926. He was appointed as the Captain and ADC to Maharaja Ganga Singh ji ( the famous 21st ruler of Bikaner) in 1927. </li><li><i class="bi bi-star-fill history-icons"></i> He was promoted to the rank of Major in 1933 and became the Military Secretary in 1934.He accompanied the Maharaja to several national and international conferences held in Europe. He attended the Function of Silver Jubilee of King George V in 1935 at London as the Chief of Staff and also participated in the coronation ceremony of king George VI in 1937.</li>
                            <li><i class="bi bi-star-fill history-icons"></i> Late Rao Bahadur, Colonel, Thakur Jeoraj Singh was bestowed with the title of Rao Bahadur by the Viceroy of India in 1936.</li>
                        </p>
                    </div>
                </div>
                <div className="row mt-5 gy-5 gx-5 align-items-center">
                    <div className="col-lg-2 col-md-4 history-image">
                        <img src="Images/About/history1.webp"  alt="" />
                    </div>
                    <div className="col-lg-10 col-md-8 history-content">
                        <p>
                            <li><i class="bi bi-star-fill history-icons"></i> Shri Arjun Singh Harasar has vast experience of hospitality industry as he has served for 34 years in the Tourism Department, Govt. of Rajasthan. He started the Hotel Desert Winds with 6 rooms in 1995. The Hotel has now been renovated & extended to 22 well furnished rooms. The rooms are well equipped with all modern facilities. Sh. Arjun Singh is always ready to offer tourism related information and share his rich experiences of tourism.</li>
                        </p>
                    </div>
                </div>
                <div className="row mt-5 gy-5 gx-5 align-items-center">
                    <div className="col-lg-2 col-md-4 history-image">
                        <img src="Images/About/history3.webp"  alt="" />
                    </div>
                    <div className="col-lg-10 col-md-8 history-content">
                        <p>
                            <li><i class="bi bi-star-fill history-icons"></i> Thakur Vikram Singh Harasar grandson of Late Rao Bahadur, Colonel, Thakur Jeoraj Singh of Harasar and son of Shri Arjun Singh Harasar.</li><li><i class="bi bi-star-fill history-icons"></i>  He has been in the Hospitality and Tourism industry since 30 years and has expanded the hotel business even further. The Hotel has now been renovated & extended to 22 well furnished rooms. </li>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
        </>
    )
}
