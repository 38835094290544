import React from 'react'

export default function Gallery() {
    
   

    return (

        <>

            <div className="gallery mt-5 pt-3 mb-5">
                <div className="container">
                    <div className="row title align-items-center">
                        <div className="col text-start">
                            <h2 className="display-6 fw-normal">The Gallery</h2>

                        </div>
                        <div className="col subtitle text-end">
                            PICTURE PERFECT VIEWS
                        </div>
                    </div>

                    <div className="row mt-5 gx-2 gy-2 pt-5">
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">                                
                                <img  className="gallery-images" src="Images/Gallery/about13.webp" alt=""/>
                                
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about1.webp" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about2.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about3.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about4.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about5.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about6.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about7.webp" alt="" /></div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about12.webp" alt="" />
                            </div></div>

                        <div className="col-lg-4 col-md-6">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about14.webp" alt="" />
                            </div></div>
                        <div className="col-lg-4 col-md-6">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about8.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about9.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about10.webp" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-md-6 ">
                            <div className="gallery">
                                <img className="gallery-images" src="Images/Gallery/about11.webp" alt="" /></div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
