import React, { useState } from 'react'

export default function Header() {


  


  return (
    <div className="header">
        <div className="container">
            <div className="row p-2">
            <div className="col-lg-7">
                <a className="header-links" href="mailto:infohoteldesertwinds@gmail.com"><i className="bi bi-envelope-fill"></i> infohoteldesertwinds@gmail.com</a>
                <a className="header-links m-4" href="tel:+919828067400"><i className="bi bi-telephone-fill"></i> +91 9828067400</a>
            </div>
            {/* <div className="col-lg-3">
            <a className="header-links" href="tel:+919828067400"><i className="bi bi-telephone-fill"></i> +91 9828067400</a>
            </div> */}
            <div className="col text-end">
                <a href="https://instagram.com/hoteldesertwinds" target="blank"><i className="bi bi-instagram m-3 social-icons"></i></a>
                <a href="https://facebook.com/hoteldesertwinds" target="blank"><i className="bi bi-facebook m-3 social-icons"></i></a>
            </div>
            </div>
        </div>
    </div>
  )
}
