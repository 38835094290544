import React from 'react'

export default function Contact() {

   

    

    return (
        <><div className="about-page mt-5 pt-3 mb-5">
            <div className="container">
                <div className="row title align-items-center mb-5">
                    <div className="col text-start">
                        <h2 className="display-6 fw-normal">Contact Us</h2>
                    </div>
                    <div className="col subtitle text-end align-items-center">
                        WE'D LIKE TO HEAR FROM YOU
                    </div>
                </div>
                <div className="row gy-4 mb-5 align-items-center text-center">
                    <div className="col-lg-4"><i class="bi bi-geo-alt-fill contact-icons fs-1"></i><h4 className="pt-3">Address</h4><a className="contact-links" href="https://www.google.com/maps/dir//Hotel+Desert+Winds,+Karni+Singh+Stadium+Rd,+Transport+Nagar,+Mehron+Ka+Bas,+Bikaner,+Rajasthan+334001/" target="blank"><p>Opposite Karni Singh Stadium, Bikaner, Rajasthan, 334001</p></a></div>
                    <div className="col-lg-4"><i class="bi bi-telephone-fill contact-icons fs-1"></i><h4 className="pt-3">Call Today</h4><a className="contact-links" href="tel:+919828067400" target="blank"><p>+91 9828067400</p></a></div>
                    <div className="col-lg-4"><i class="bi bi-envelope-fill contact-icons fs-1"></i><h4 className="pt-3">Drop an Email</h4><a className="contact-links" href="mailto:infohoteldesertwinds@gmail.com" target="blank"><p>infohoteldesertwinds@gmail.com</p></a></div>

                </div>

<div className="row">
    
    </div>               

                <div className="row align-items-center my-2 text-center">
                    <div className="col-lg-12 map-column">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3521.890758405001!2d73.32246277606586!3d28.027814511283243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393fdd7cad319ee1%3A0xcc90f398ff58b72d!2sHotel%20Desert%20Winds!5e0!3m2!1sen!2sin!4v1687624079888!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>

            </div>
        </div>
        </>
    )
}
