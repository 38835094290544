import React from 'react'

export default function Footer() {
  return (
    <div className="footer py-2 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12 text-center">
            <h6>
              &copy;{new Date().getFullYear()}  Hotel Desert Winds. All Rights Reserved.
            </h6>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 text-center">
            <a href="https://instagram.com/hoteldesertwinds" target="blank"><i className="bi bi-instagram m-3 social-icons"></i></a>
            <a href="https://facebook.com/hoteldesertwinds" target="blank"><i className="bi bi-facebook m-3 social-icons"></i></a>
          </div>
        </div>
      </div>
    </div>
  )
}
