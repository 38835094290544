import React, { useState } from 'react'

export default function GoToTop() {

  const [gototop, setTopBtn] = useState(false);
    const displayTopbtn = () => {
        if (window.scrollY >= 160) {
            setTopBtn(true);
        }
        else {
            setTopBtn(false);
        }
    };
    window.addEventListener('scroll', displayTopbtn)



const goToBtn = () =>{
    window.scrollTo({top: 0, left: 0, behaviour: "smooth"})
}

  return (
    <div className={gototop ? 'top-btn animate__animated animate__fadeIn' : 'top-btn-hide '} onClick={goToBtn}>
     <i className="bi bi-arrow-up-circle-fill up-arrow "></i>
    </div>
  )
}