import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function Navbar() {

  var spinner = document.getElementById("spinner")
  function Imageonload() {
    spinner.style.display = "none";
  }

  // Function to close hamburger
  const [navOpen, setNavOpen] = useState(false);

  function toggleNav() {
    setNavOpen((state) => !state);
  }


  return (
    <>
      {/* Navbar starts */}

      <nav class="navbar navbar-expand-lg m-0 p-0 bg-body-tertiary">
        <div className="container">

          <NavLink reloadDocument className="navbar-brand p-0 m-0" to="/"><img src="Images/Navbar/hotellogo.jpg" className="nav-logo" alt="" /></NavLink>

          <button onClick={toggleNav} className={navOpen ? "navbar-toggler" : "navbar-toggler collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={
            navOpen
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse"
          } id="navbarSupportedContent">

            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <NavLink onClick={toggleNav} className="nav-link fw-bold me-3" to="/home" >HOME</NavLink>
              </li>
              <li className="nav-item dropdown">
                <a onClick={toggleNav} type="button" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link fw-bold me-3 dropdown-toggle" to="/about">HOTELS</a>
                <ul class="dropdown-menu">
                  <a class="dropdown-item" href="https://bikanerbaagh.com" >Bikaner Baagh</a>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink onClick={toggleNav} className="nav-link fw-bold me-3" to="/about">HOTEL OVERVIEW</NavLink>
              </li>
              <li className="nav-item">
                <NavLink onClick={toggleNav} className="nav-link fw-bold me-3" to="/accomodation">ACCOMODATION</NavLink>
              </li>
              <li className="nav-item">
                <NavLink onClick={toggleNav} className="nav-link fw-bold me-3" to="/gallery" >GALLERY</NavLink>
              </li>
              <li className="nav-item">
                <NavLink onClick={toggleNav} className="nav-link fw-bold me-3" to="/contact">CONTACT</NavLink>
              </li>
            </ul>

          </div>
        </div>
      </nav>
    </>
  )
}
