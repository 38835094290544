import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Home() {

    

    return (
        <>
            <div className="home-about mt-5 mb-5 py-5 ">

                <div className="container">
                    <div className="home1">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="about-content">
                                    <h2 className="mt-3 mb-5">REDEFINES THE<span> LUXURY HOSPITALITY </span>EXPERIENCE.
                                    </h2>
                                    <h5>HOTEL DESERT WINDS</h5>
                                    <p className="mt-3">
                                        Lying in the north of the desert state, BIKANER the heritage city is deep in the desert on the ancient caravan routes. Bikaner is renowned for its rich tradition, culture, fort & Palaces. The Hotel Desert Winds is a unique way to experience BIKANER, where hospitality is redefined. The architecture of Hotel Desert Winds is a blend of ancient and modern times. It has articulately made stone Jharokha's and Todis.  From the Hotel roof top one can have a splendid view of the unassaible fortress Junagarh and the BIKANER city.</p>

                                    <NavLink  to="/about">
                                        <button className="more-btn mt-2 ">
                                            Know About Us
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="about-image">
                                    <div className="image-box d-flex">
                                        <div className="about-image-1 w-50">
                                            <img src="Images/Home/gate.webp"  alt="" />
                                        </div>
                                        <div className="about-image-2 w-50">
                                            <img src="Images/Home/camel.webp"  alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-room bg-body-tertiary mb-5">
                <div className="container py-5">
                    <div className="home2">
                        <div className="row gy-5 mt-5 mb-5">
                            <div className="col-lg-4 col-md-12"><div className="about-content">
                                <h2 className="mt-3 mb-5">DISCOVER<span> OUR ROOMS </span>
                                </h2>
                                <p>
                                    All the rooms are set in tranquil surroundings. All the 22 rooms are well furnished with modern comfort. The interiors have been tastefully designed with ethnic furnishings. Facility of in room dining is available for guests who after a hectic sight seeing tour may prefer.
                                </p>
                                <NavLink to="/accomodation">
                                    <button className="more-btn mb-5 mt-2">
                                        Check our Tariff
                                    </button>
                                </NavLink>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6">

                                <div className="card mx-3">
                                    <img src="Images/Home/room2.webp" loading='lazy' className="card-img-top" alt="" />
                                    <div className="card-body p-4">
                                        <h5 className="card-title">DELUXE ROOMS</h5>
                                        <p className="card-text">Comfortable well lit air-conditioned room with all basic amenities. It is equipped with a cable TV and a telephone with direct dial facility.
                                            Best suitable for two guests. </p>

                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card mx-3">
                                    <img src="Images/Home/room1.webp" loading='lazy'className="card-img-top" alt="" />
                                    <div className="card-body p-4">
                                        <h5 className="card-title">SUPER DELUXE ROOMS</h5>
                                        <p className="card-text">Well furnished and equipped with all the basic amenities available This spacious, air-conditioned room is decorated with classy ethnic furnitures, comprising a double bed, a mirror, an arm-chair and a sofa set with a coffee table.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-services mb-5 py-4">
                <div className="container py-5">
                    <div className="home3">
                        <div className="row gy-5 mb-5 text-center">
                            <div className="about-content">
                                <h2 className="mt-3 mb-5">AMENITIES<span> OFFERED </span>
                                </h2>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="services-block py-3">
                                    <i class="bi bi-fan fs-2"></i><h5 className="pt-3">Air-conditioning</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="services-block py-3">
                            <i class="bi bi-wifi fs-2"></i><h5 className="pt-3">Free WiFi</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="services-block py-3">
                            <i class="bi bi-car-front-fill fs-2"></i><h5 className="pt-3">Transport</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="services-block py-3">
                            <i class="bi bi-p-circle-fill fs-2"></i><h5 className="pt-3">Free Parking</h5>
                                </div>
                            </div>
                        
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="services-block py-3">
                                <i class="bi bi-basket-fill fs-2"></i><h5 className="pt-3">Laundry Service</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="services-block py-3">
                            <i class="bi bi-prescription2 fs-2"></i><h5 className="pt-3">Doctor on Call</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="services-block py-3">
                            <i class="bi bi-plug-fill fs-2"></i><h5 className="pt-3">Power backup</h5>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="services-block py-3">
                            <i class="bi bi-person-fill-check fs-2"></i><h5 className="pt-3">Dining</h5>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>

            <div className="home-sights bg-body-tertiary mt-5 py-5">
                <div className="container">
                    <div className="home4">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about-content">
                                    <h2 className="mt-3 mb-5">BIKANER <span> TOP ATTRACTIONS </span>
                                    </h2>
                                    <p>
                                        No itinerary is complete without Rajasthan as its one of the destination.
                                        Lying in the north of the desert state, BIKANER the heritage city is deep in the desert on the ancient caravan routes. Bikaner is renowned for its rich tradition, culture, fort & Palaces.
                                    </p>

                                    {/* <NavLink to="/sightseeing">
                                        <button className="more-btn mb-5 mt-2">
                                            More Sightseeing
                                        </button>
                                    </NavLink> */}
                                </div>
                            </div>
                        </div>
                        <div className="row gy-5 mt-3  text-center">
                            <div className="col-lg-3 col-sm-6 col-md-6 sight-col">
                                <div className="sight">
                                    <img src="Images/Home/rampuria-havelli.webp" loading='lazy'alt="" />
                                    <div className="sight-detail-bottom py-3">
                                        <div className="sight-info">
                                            <h5>Rampuria Haveli
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6 sight-col">
                                <div className="sight">
                                    <img src="Images/Home/junagarh-fort.webp" loading='lazy'alt="" />
                                    <div className="sight-detail-bottom py-3">
                                        <div className="sight-info">
                                            <h5>Junagarh Fort
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6 col-md-6 sight-col">
                                <div className="sight">
                                    <img src="Images/Home/devi-kund-sagar.webp" loading='lazy'alt="" />
                                    <div className="sight-detail-bottom py-3">
                                        <div className="sight-info">
                                            <h5>Devi Kund Sagar
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6 col-md-6 sight-col">
                                <div className="sight">
                                    <img src="Images/Home/deshnok-temple.webp"loading='lazy' alt="" />
                                    <div className="sight-detail-bottom py-3">
                                        <div className="sight-info">
                                            <h5>Deshnok Temple
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
